import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./partial/MainLayout";
import LoadingPage from "./pages/LoadingPage";
import ProtectedRouter from "./auth/ProtectedRouter";
import ComingSoon from "./pages/ComingSoon";

const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const DokumenExpor = React.lazy(() => import("./pages/DokumenExpor"));
const InputDokumen = React.lazy(() => import("./pages/InputDokumen"));
const DokumenMap = React.lazy(() =>
  import("./component/map/dokumen/DokumenMap")
);
const Home = React.lazy(() => import("./pages/Home"));
const Dokumen40Map = React.lazy(() =>
  import("./component/map/dokumen/Dokumen40Map")
);
const InputDokTPB = React.lazy(() => import("./pages/InputDokTPB"));
const ListDokumenTPB = React.lazy(() => import("./pages/ListDokumenTPB"));
const UserSetup = React.lazy(() => import("./pages/UserSetup"));
const DaftarPerusahaan = React.lazy(() => import("./pages/DaftarPerusahaan"));
const NoRoute = React.lazy(() => import("./pages/NoRoute"));

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        element={
          <ProtectedRouter>
            <Suspense fallback={<LoadingPage />}>
              <MainLayout />
            </Suspense>
          </ProtectedRouter>
        }
      >
        <Route path="home" element={<Home />} />
        <Route path="account" element={<UserSetup />} />
        <Route path="register" element={<Register />} />
        <Route path="perushaan" element={<DaftarPerusahaan />} />

        <Route element={<DokumenMap />}>
          <Route path="docekspor" element={<DokumenExpor />} />
          <Route path="docimport" element={<ComingSoon />} />
          <Route path="inputdokumen" element={<InputDokumen />} />
        </Route>
        <Route element={<Dokumen40Map />}>
          <Route path="dok40" element={<ListDokumenTPB />} />
          <Route path="dok41" element={<ListDokumenTPB />} />
          <Route path="dok23" element={<ListDokumenTPB />} />
          <Route path="dok25" element={<ListDokumenTPB />} />
          <Route path="inputdokumen-tpb" element={<InputDokTPB />} />
        </Route>
        <Route path="*" element={<NoRoute />} />
      </Route>
    </Routes>
  );
}

export default App;
